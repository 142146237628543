/* --------------------------------------------- 
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
/* --------------------------------------------- */

@import 'variable';

@import 'mixins/spacing';
@import 'mixins/color';
@import 'mixins/font';

@import 'component/global';
@import 'component/heading';

@import 'component/header';
@import 'component/sidebar';
@import 'component/spinner';
@import 'component/card';
@import 'component/dashboard';

@import 'responsive';

@import '../../global';

@import "../styles/icons/material-design-iconic-font/css/materialdesignicons.min.css";