/* You can add global styles to this file, and also import other style files */
.example-sidenav-container{
    background-color:$backgroundGlobalRose;
}

#snav .mat-list-item.selected > .mat-list-item-content > a{
    background: $danger;
}

.mat-button-wrapper{
    color:#fc4b6c;
}

.mat-icon-button .mat-button-wrapper{
    color:white;
}

.mat-icon-button .mat-button-wrapper .mat-icon-no-color{
    color:#fc4b6c;
}


.chars .pie .apexcharts-tooltip-series-group{
    background-color: lightgray !important; 
    color:#454545 !important;
}